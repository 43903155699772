import React from "react";
import "./heroProduct.css"



const Hero = () => {
  return (
    <>
      <section className='hero-product'>
        <div className='conatiner' style={{alignItems: "center", textAlign: "center"}}>
          <div className='row' style={{alignItems: "center", justifyContent: "center"}}>
          </div>
        </div>
      </section>
    </>
  )
}

export default Hero